import React from 'react';
import PublicRoute from './services/auth/PublicRoute';
import ErrorBoundary from './component/errorBoundaryComponent/ErrorBoundary';

// Lazy load your components
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const CheckEmail = React.lazy(() => import('./views/pages/forgetPassword/CheckEmail'));
const CreatePassword = React.lazy(() => import('./views/pages/forgetPassword/CreatePassword'));
const PasswordReset = React.lazy(() => import('./views/pages/forgetPassword/PasswordReset'));
const SetupWelcome = React.lazy(() => import('./views/pages/setepForm/SetupWelcome'));
const PaymentPage = React.lazy(() => import('./views/pages/setepForm/PaymentPage'));
const UserDashboard = React.lazy(() => import('./views/pages/dashboard/UserDashboard'));
const AdminDashboard = React.lazy(() => import('./views/pages/dashboard/AdminDashboard'));
const SettingPage = React.lazy(() => import('./views/pages/settings/settings'));
const UserListPage = React.lazy(() => import('./views/pages/Users/UsersList'));
const OpportunityCalculator = React.lazy(() => import('./views/pages/opportunityCalculator/OpportunityCalculator'));
const AdminUsersSettingPage = React.lazy(() => import('./views/pages/settings/AdminUserSettings'));

const routes = [
    { path: '/', name: 'Login', element: <PublicRoute><ErrorBoundary><Login /></ErrorBoundary></PublicRoute> },
    { path: '/register', name: 'Register', element: <PublicRoute><ErrorBoundary><Register /></ErrorBoundary></PublicRoute> },
    { path: '/check-email', name: 'CheckEmail', element: <PublicRoute><ErrorBoundary><CheckEmail /></ErrorBoundary></PublicRoute> },
    { path: '/create-password', name: 'CreatePassword', element: <PublicRoute><ErrorBoundary><CreatePassword /></ErrorBoundary></PublicRoute> },
    { path: '/password-reset', name: 'PasswordReset', element: <PublicRoute><ErrorBoundary><PasswordReset /></ErrorBoundary></PublicRoute> },
    { path: '/welcome', name: 'SetupWelcome', element: <ErrorBoundary><SetupWelcome /></ErrorBoundary> },
    { path: '/card-details', name: 'PaymentPage', element: <ErrorBoundary><PaymentPage /></ErrorBoundary> },
    { path: '/user-dashboard', name: 'UserDashboard', element: <ErrorBoundary><UserDashboard /></ErrorBoundary> },
    { path: '/opportunity-calculator', name: 'OpportunityCalculator', element: <ErrorBoundary><OpportunityCalculator /></ErrorBoundary> },
    { path: '/admin-dashboard', name: 'AdminDashboard', element: <ErrorBoundary><AdminDashboard /></ErrorBoundary> },
    { path: '/users-list', name: 'UsersList', element: <ErrorBoundary><UserListPage /></ErrorBoundary> },
    { path: '/settings', name: 'SettingsPage', element: <ErrorBoundary><SettingPage /></ErrorBoundary> },
    { path: '/users-list/:userId', name: 'AdminUserSettings', element: <ErrorBoundary><AdminUsersSettingPage /></ErrorBoundary> },
];

export default routes;
