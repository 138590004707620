import React, { Component } from 'react';
import './ErrorBoundary.css'; // Import CSS for styling

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error("Error caught by ErrorBoundary: ", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="error-boundary">
                    <div className="error-boundary-content">
                        <h2 className="title text-primary">Oops! Something went wrong.</h2>
                        <p className="text-grey">We're sorry for the inconvenience. Please try refreshing the page, or contact support if the issue persists.</p>
                        <button
                            className="refresh-button"
                            onClick={() => window.location.reload()}
                        >
                            Refresh Page
                        </button>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
