import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('_token');
    const adminToken = localStorage.getItem('admin_token');
    if (accessToken) 
    {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    if(adminToken)
    {
      config.headers['Authorization'] = `Bearer ${adminToken}`;
    }
    return config;
  },
  function (error)  
  {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    
    // Check if the error is a 401 Unauthorized and if it's the first retry
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retrying
      
      try {
        // Attempt to refresh the token
        const response = await axios.post('/refresh-token', {
          refreshToken: localStorage.getItem('_refreshToken'),
        });
        
        // Save the new access token
        localStorage.setItem('_token', response.data.access_token);
        
        // Set the new token in the headers and retry the original request
        originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;
        return instance(originalRequest);
        
      } catch (refreshError) {
        // Handle token refresh failure - log out the user
        console.error('Token refresh failed:', refreshError);
        
        // Clear all relevant data from local storage
        localStorage.removeItem('_token');
        localStorage.removeItem('_refreshToken');
        localStorage.removeItem('user');
        localStorage.removeItem('admin_token');
        
        // Optionally, redirect the user to the login page
        if (window.location.pathname !== '/login') {
          window.location.href = '/login';  // Redirect to login page
        }
        
        return Promise.reject(refreshError);
      }
    }
    
    // If the error is not a 401 or the token refresh failed, reject the promise
    return Promise.reject(error);
  }
);

export default instance;
