import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from './AuthContext';

const PublicRoute = ({ children }) => {
  const { loading, user, role } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && user) {
      if (role === 'user') {
        navigate('/opportunity-calculator'); // Redirect to dashboard if logged in
      } else{
        navigate('/users-list')
      }
    }
  }, [loading, user, navigate, role]);

  if (loading) {
    return <p>Loading...</p>; // Show loading state
  }

  return children;
};

export default PublicRoute;