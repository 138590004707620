'use client';
import { createContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './axios';  // Import the configured Axios instance
import { setCookie } from '../../component/GoogleTranslate';

const AuthContext = createContext();

function getTokenExpiration(token) {
    const tokenParts = token.split('.');
    if (tokenParts.length !== 3) return null; // Invalid token

    const payload = JSON.parse(atob(tokenParts[1]));
    return payload.exp ? payload.exp * 1000 : null; // Convert to milliseconds
}

export const AuthProvider = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isNewUser, setIsNewUser] = useState(false)

    // Admin and User Profile get api.


    // Admin and User logout api
    const logout = useCallback(async () => {
        //const response = await axios.post('/user/logout');
        //console.log(response, "Check Logout Api");
        localStorage.removeItem('_token');
        localStorage.removeItem('user');
        localStorage.removeItem('role');
        localStorage.setItem('selectedLanguage', 'en')
        setCookie('googtrans', `/en/en`, 7)
        const select = document.querySelector('.goog-te-combo');
        if (select) {
            select.value = 'en';
            select.dispatchEvent(new Event('change'));
        }
        const script = document.querySelector('script[src*="translate_a/element.js"]');
        if (script) {
            script.remove();
        }
        const translateElement = document.getElementById('google_translate_element');
        if (translateElement) {
            translateElement.innerHTML = ''; // Clear the translation element to reset
        }
        localStorage.removeItem('admin_token');
        navigate('/')
        setUser(null);
    }, [navigate]);

    const setAutoLogout = useCallback((token) => {
        const expirationTime = getTokenExpiration(token);
        if (expirationTime) {
            const timeoutDuration = expirationTime - Date.now();
            setTimeout(() => {
                logout();
            }, timeoutDuration); // Log out when the token expires
        }
    }, [logout])

    useEffect(() => {
        const token = localStorage.getItem('_token');
        const user = localStorage.getItem('user');
        const role = localStorage.getItem('role');
        const adminToken = localStorage.getItem('admin_token');
        if (token) {
            setUser(user);
            setRole(role);
            setLoading(false);
            setAutoLogout(token)
        }
        if (adminToken) {
            setUser(user);
            setRole(role);
            setLoading(false);
            setAutoLogout(adminToken)
        }

        /*if (token)
        {
            axios.get('/request-user-profile') 
                .then(response => {
                    setUser(response.data);
                    console.log(response.data,"Get user Profile");
                    setLoading(false);
                })
                .catch(() => {
                    localStorage.removeItem('token');
                    setLoading(false);
                });
        }
        if(adminToken)
        {
            axios.get('/request-admin-profile') 
                .then(response => {
                    setUser(response.data);
                    console.log(response.data,"Get admin Profile");
                    setLoading(false);
                })
                .catch(() => {
                    localStorage.removeItem('admin_token');
                    setLoading(false);
                });
        }*/
        if (!token && !adminToken) {
            setLoading(false);

        }
        // else
        // {
        //     setLoading(false);
        // }
    }, [setAutoLogout]);

    // User register api
    const register = async (full_name, email, password) => {
        try {
            const response = await axios.post('/auth/register', { full_name, email, password })
            const token = response.data.authorization
            localStorage.setItem('_token', token);
            if (response.data && response.data.user) localStorage.setItem('user', JSON.stringify(response.data.user));
            window.localStorage.setItem('selectedLanguage', response.data.user.interface_language ? response.data.user.interface_language : 'en')
            localStorage.setItem('role', 'user');
            setRole('user')
            setUser(response.data.user)
            setIsNewUser(true)
            navigate('/welcome')
        }
        catch (error) {
            const errorMessage = error.response ? error.response.data : 'An error occurred';
            return errorMessage;
        }
    }

    // User login api
    const login = async (email, password) => {
        try {
            const response = await axios.post('/auth/login', { email, password });
            const token = response.data.authorization;
            console.log(response.data, "User and Admin Login");
            // navigate('/dashboard');
            if (response.data.role === "admin") {
                setUser(response.data.user)
                setRole(response.data.role)
                localStorage.setItem('admin_token', token);
                if (response.data && response.data.user) localStorage.setItem('user', JSON.stringify(response.data.user));
                localStorage.setItem('role', response.data.role);
                navigate('/users-list');
            }
            if (response.data.role === "user") {
                setUser(response.data.user)
                setRole(response.data.role)
                localStorage.setItem('_token', token);
                if (response.data && response.data.user) localStorage.setItem('user', JSON.stringify(response.data.user))
                window.localStorage.setItem('selectedLanguage', response.data.user.interface_language ? response.data.user.interface_language : 'en');
                localStorage.setItem('role', response.data.role);
                navigate('/opportunity-calculator', {state: 'initialized'});
            }
        }
        catch (error) {
            const errorMessage = error.response ? error.response.data : 'An error occurred';
            return errorMessage;
        }
    };

    // User login with google api
    const login_with_google = async (email, full_name, password, google_id) => {
        const response = await axios.post('/auth/login-with-google', { email, full_name, password, google_id });
        const token = response.data.authorization.token;
        localStorage.setItem('_token', token);
        localStorage.setItem('role', 'user');
        setRole('user')
        setUser(response.data.user);
        if (response.data && response.data.user) localStorage.setItem('user', JSON.stringify(response.data.user));
        window.localStorage.setItem('selectedLanguage', response.data.user.interface_language ? response.data.user.interface_language : 'en');
        //navigate('/welcome')
        if (response.data.isNewCreated) {
            setIsNewUser(true)
            navigate('/welcome')
        } else {
            navigate('/opportunity-calculator')
        }
    };

    // Admin login api
    const admin_login = async (email, password) => {
        try {
            const response = await axios.post('/auth/admin-login', { email, password });
            const token = response.data.authorization;
            // navigate('/dashboard');
            setRole('admin')
            localStorage.setItem('admin_token', token);
            setUser(response.data.user);
        }
        catch (error) {
            const errorMessage = error.response ? error.response.data : 'An error occurred';
            console.log("in Error Api", error.response.data)
            return errorMessage;
        }
    };

    const markUserAsNotNew = () => {
        setIsNewUser(false);
    }

    return (
        <AuthContext.Provider value={{ user, role, loading, login, logout, register, isNewUser, markUserAsNotNew, admin_login, login_with_google }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
