import React, { useEffect } from 'react';
import './Translate.css'

export const setCookie = (cName, cValue, exDays) => {
    document.cookie = cName + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    let d = new Date(),
        expires = exDays;
    if (typeof exDays === 'number') {
        d.setTime(d.getTime() + (exDays * 24 * 60 * 60 * 1000));
        expires = "expires=" + d.toUTCString();
    }
    document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
}

const GoogleTranslate = ({ onLanguageChange }) => {
    const defaultLanguage = window.localStorage.getItem('selectedLanguage') || 'en'

    useEffect(() => {

        const addLanguageChangeListener = () => {
            const select = document.querySelector('.goog-te-combo');
            if (select) {
                select.addEventListener('change', () => {
                    const selectedLanguage = select.value;
                    //window.localStorage.setItem('selectedLanguage', selectedLanguage);
                    setCookie('googtrans', `/en/${selectedLanguage}`, 7);
                    if (onLanguageChange) onLanguageChange(selectedLanguage)
                });
            }
        }

        // Initialize Google Translate
        window.googleTranslateElementInit = async () => {
            await new window.google.translate.TranslateElement(
                'google_translate_element'
            );
            addLanguageChangeListener()
        }

        const addGoogleTranslateScript = () => {
            // Check if the script already exists
            const existingScript = document.querySelector('#google-translate-script');
            if (!existingScript) {
                // Create and append the script
                const script = document.createElement('script');
                script.id = 'google-translate-script';
                script.type = 'text/javascript';
                script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                document.body.appendChild(script);
            } else {
                // If script already exists, trigger the init function again to re-render
                window.googleTranslateElementInit();
            }
        };

        addGoogleTranslateScript()

        return () => {
            // Optional: Cleanup the script if needed (if you want to remove it when the component unmounts)
            const script = document.getElementById('google-translate-script');
            if (script) {
                script.remove();
            }
            // Remove Google Translate instance to avoid duplicates on re-render
            const translateElement = document.getElementById('google_translate_element');
            if (translateElement) {
                translateElement.innerHTML = ''; // Clear content on unmount
            }
        };
    }, []);

    useEffect(() => {
        const updateLanguageInWidget = (language) => {
            const interval = setInterval(() => {
                const select = document.querySelector('.goog-te-combo');
                if (select && select.options.length > 0) {
                    const optionExists = Array.from(select.options).some(option => option.value === language)
                    if (optionExists) {
                        select.value = language; // Set the value in the select box
                        setCookie('googtrans', `/en/${language}`, 7)
                        const event = new Event('change');
                        select.dispatchEvent(event);
                        clearInterval(interval)
                    }
                }
            }, 1000);
        }
        updateLanguageInWidget(defaultLanguage)
    }, [defaultLanguage])

    return <div id="google_translate_element" className="custom-translate"></div>; // Placeholder div for the widget
};

export default GoogleTranslate;
