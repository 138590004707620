import React, { Suspense } from "react";
import "./App.css";
import routes from "./routes";
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import useLoading from "./component/loadingComponent/UseLoading.js";
import LoadingAnimation from "./component/loadingComponent/LoadingAnimation.js";

function App() {
  const loading = useLoading(5000); // Custom hook for loading state
  const location = useLocation();

  return (
    <div className="App">
      {loading ? (
        <LoadingAnimation />
      ) : (
        <Suspense fallback={<LoadingAnimation />}>
          <AnimatePresence mode="wait">
            <Routes location={location} key={location.key}>
              {routes.map((route, index) => (
                <Route
                  key={location.key + index} // Use location key combined with index
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </AnimatePresence>
        </Suspense>
      )}
    </div>
  );
}

export default App;
